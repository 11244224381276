import "./App.module.scss";
import "react-dates/initialize";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import history from "services/browserHistory";
import ErrorBoundary from "./ErrorBoundary";
import MainLayout from "./layouts/MainLayout/MainLayout";
import FakeLayout from "./layouts/FakeLayout";
import UnauthLayout from "components/layouts/UnauthLayout/UnauthLayout";
import Login from "./routes/authGroup/Login";
import Signup from "./routes/authGroup/Signup";
import ResetPassword from "./routes/authGroup/ResetPassword";
import Home from "./routes/Home";
import FlowsGroupRoutes, { path as flowsGroupPath } from "./routes/flowsGroup";
import FlowConstructorRoute, { path as flowConstructorPath } from "./routes/FlowConstructor";
import TemplatesGroupRoutes, { path as templatesPath } from "./routes/Templates";
import SettingsGroupRoutes, { path as settingsGroupPath } from "./routes/SettingsGroup";
import UserSettingsGroupRoutes, { path as userSettingsGroupPath } from "./routes/UserSettingsGroup";
import CrmRoutes, { path as crmPath } from "./routes/crmGroup";
import PluginGroupRoute, { path as pluginPath } from "./routes/PluginGroup";
import MessagesRoute, { path as messagesPath } from "./routes/Messages/routing";
import Playground, { playgroundPath } from "./routes/Playground";
import { ModalChooseTemplateContainer } from "components/routes/flowsGroup/Flow/Templates/Modal";
import NotFoundRoute from "./routes/NotFound";
import DashboardPage from "./routes/dashboard";
import SenderProfilesPage from "./routes/SenderProfileGroup";
import TasksPage from "./routes/Tasks";
import authPath from "./routes/authGroup/Signup/path";
import { OauthPage } from "./routes/authGroup/Oauth/OauthPage";
import { oauthPath } from "./routes/authGroup/Oauth/path";
import dashboardPath from "./routes/dashboard/const";
import { senderProfilesPath } from "components/routes/SenderProfileGroup/paths";
import tasksPath from "./routes/Tasks/path";
import { ModalController } from "feature/modal/component/ModalController";
import { Theme } from "components/Theme";
import { AppWrapper } from "@gs/uiKit/layout/AppWrapper";
import { useCurrentUser } from "feature/session/useCurrentUser";
import { Input } from "@gs/uiKit/input/Input";
import { userConfirmationPath } from "components/routes/authGroup/UserConfirmation/path";
import { UserConfirmation } from "components/routes/authGroup/UserConfirmation/UserConfirmation";

const App = () => {
  const { currentUserStatus } = useCurrentUser();

  // import * as firebase from "firebase/app";
  // import { getAnalytics } from "firebase/analytics";
  // import { firebaseConfig } from "../config";
  // it breaks analytics from GoogleTagManager
  // React.useEffect(() => {
  //   const apps = firebase.getApps();
  //   let app;
  //
  //   if (!apps.length) {
  //     app = firebase.initializeApp(firebaseConfig);
  //   } else {
  //     app = apps[0];
  //   }
  //
  //   getAnalytics(app);
  // }, []);

  // отключить браузер Safari
  const ua = navigator.userAgent.toLowerCase();
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !/ crios\/\d/.test(ua)) {
    return (
      <div style={{ display: "flex", height: "100vh" }}>
        <div style={{ margin: "auto", fontSize: 18, width: 400, textAlign: "center" }}>
          Unfortunately we do not support the Safari browser yet. Please use
          <a
            style={{ fontSize: 18 }}
            href="https://www.google.com/intl/en_us/chrome/"
          >
            {" "}
            Google Chrome
          </a>
        </div>
      </div>
    );
  }

  return (
    <Theme>
      {/* we need it to force antd add css variables (i.e. --input-hover-border-color) to <body> */}
      <Input style={{ display: "none" }} />

      <AppWrapper>
        <ErrorBoundary>
          <ModalController />
          <ReactTooltip
            effect="solid"
            place="bottom"
          />

          <Router history={history}>
            <Switch>
              <Route
                path={playgroundPath}
                component={Playground}
              />

              {currentUserStatus === "unauthorized" && (
                <Route path={[`${flowsGroupPath}/templates/:templateId`]}>
                  <FakeLayout>
                    <Switch>
                      <Route
                        exact
                        path={`${flowsGroupPath}/templates/:templateId`}
                        component={ModalChooseTemplateContainer}
                      />
                    </Switch>
                  </FakeLayout>
                </Route>
              )}

              <Route path={[authPath.login, authPath.signup, userConfirmationPath, authPath.reset, oauthPath, "/oauth" ]}>
                <UnauthLayout>
                  <Switch>
                    <Route
                      exact
                      path={oauthPath}
                      component={OauthPage}
                    />
                    <Route
                      exact
                      path={authPath.login}
                      component={Login}
                    />
                    <Route
                      exact
                      path={userConfirmationPath}
                      component={UserConfirmation}
                    />
                    <Route
                      exact
                      path={authPath.signup}
                      component={Signup}
                    />
                    <Route
                      exact
                      path={authPath.reset}
                      component={ResetPassword}
                    />
                    <Route component={Home} />
                  </Switch>
                </UnauthLayout>
              </Route>

              <Route
                path={flowConstructorPath}
                component={FlowConstructorRoute}
              />

              <Route>
                <MainLayout>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={Home}
                    />
                    <Route
                      path={settingsGroupPath}
                      component={SettingsGroupRoutes}
                    />
                    <Route
                      path={userSettingsGroupPath}
                      component={UserSettingsGroupRoutes}
                    />
                    <Route
                      path={flowsGroupPath}
                      component={FlowsGroupRoutes}
                    />
                    <Route
                      path={crmPath}
                      component={CrmRoutes}
                    />
                    <Route
                      path={templatesPath}
                      component={TemplatesGroupRoutes}
                    />
                    <Route
                      path={pluginPath}
                      component={PluginGroupRoute}
                    />
                    <Route
                      path={messagesPath}
                      component={MessagesRoute}
                    />
                    <Route
                      path={tasksPath}
                      component={TasksPage}
                    />
                    <Route
                      path={dashboardPath}
                      component={DashboardPage}
                    />
                    <Route
                      path={senderProfilesPath}
                      component={SenderProfilesPage}
                    />
                    <Route component={NotFoundRoute} />
                    {" "}
                    {/* 404 */}
                  </Switch>
                </MainLayout>
              </Route>
            </Switch>
          </Router>
        </ErrorBoundary>
      </AppWrapper>
    </Theme>
  );
};

export {
  App,
};
